import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useSkudhsList() {
  // console.log(router.currentRoute.params)

  // Use toast
  const toast = useToast()

  const refSkudhListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'event_time', label: i18n.t('telemetry.time'), sortable: true },
    {
      key: 'company_name',
      label: i18n.t('companies.company'),
      formatter: title,
      sortable: true,
    },
    /*
    {
      key: 'object_name',
      label: i18n.t('locations.location'),
      formatter: title,
      sortable: true,
    },
    */
    {
      key: 'skud_name',
      label: i18n.t('skuds.skud'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'type',
      label: '',
      sortable: true,
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'start',
      label: i18n.t('skuds.start'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'amount',
      label: i18n.t('skuds.credit'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'finish',
      label: i18n.t('skuds.finish'),
      formatter: title,
      sortable: true,
    },
  ]
  const perPage = ref(10)
  const totalSkudhs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('event_time')
  const isSortDirDesc = ref(true)
  const rangePicker = ref([moment().set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString(), moment().set({
    hour: 23, minute: 59, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString()])

  if (router.currentRoute.params.machineFilter) {
    store.commit('appConfig/UPDATE_MACHINE_FILTER', router.currentRoute.params.machineFilter)
    store.commit('appConfig/UPDATE_OBJECT_FILTER', null)
    store.commit('appConfig/UPDATE_COMPANY_FILTER', null)
  }

  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSkudhListTable.value ? refSkudhListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSkudhs.value,
    }
  })

  const fetchSkudhs = (ctx, callback) => {
    store
      .dispatch('app-skudh/fetchSkudhs', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        company: companyFilter.value,
        object: objectFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        lang: i18n.locale,
      })
      .then(response => {
        const { skudhs, total } = response.data

        callback(skudhs)
        totalSkudhs.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching skudhs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    fetchSkudhs()
    refSkudhListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    refetchData()
  })

  const fetchSkudhsFile = (type = 'xlsx') => {
    store
      .dispatch('app-skudh/fetchSkudhs', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        company: companyFilter.value,
        object: objectFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        lang: i18n.locale,
        type,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Skudhs.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteSkudh = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Skudh <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-skudh/deleteSkudh', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Skudh ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Skudh delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const machineOptions = ref([])
  store.dispatch('app-skudh/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const objectOptions = ref([])
  store.dispatch('app-skudh/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-skudh/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSkudhStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveSkudhCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveSkudhMachineText = machine => {
    if (!machine) {
      return ''
    }
    try {
      return machineOptions.value.find(o => o.value === machine).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchSkudhs,
    fetchSkudhsFile,
    tableColumns,
    perPage,
    currentPage,
    totalSkudhs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSkudhListTable,

    resolveSkudhStatusVariant,
    resolveSkudhCompanyText,
    resolveSkudhMachineText,
    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,
    statusFilter,

    companyOptions,
    objectOptions,
    machineOptions,

    deleteSkudh,

    rangePicker,

  }
}
