<template>

  <div>
    <!--skudh-list-add-new
      :is-add-new-skudh-sidebar-active.sync="isAddNewSkudhSidebarActive"
      :machine-options="machineOptions"
      :company-options="companyOptions"
      @refetch-data="refetchData"
    /-->

    <!-- Filters -->
    <skudhs-list-filters
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"

      :object-filter.sync="objectFilter"
      :object-options="objectOptions"

      :machine-filter.sync="machineFilter"
      :machine-options="machineOptions"

      :range-picker.sync="rangePicker"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('common.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!--b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search')"
              /-->
              <!--b-button
                variant="primary"
                @click="isAddNewSkudhSidebarActive = true"
              >
                <span class="text-nowrap">Add Skudh</span>
              </b-button-->

              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="fetchSkudhsFile()">
                  <feather-icon icon="AlignJustifyIcon" />
                  <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :disabled="true"
                  @click="fetchSalesFile('pdf')"
                >
                  <feather-icon icon="BookIcon" />
                  <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                variant="flat-info"
                class="btn-icon rounded-circle"
                @click="refetchData"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                />
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSkudhListTable"
        class="position-relative"
        :items="fetchSkudhs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Skudh -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to_="{ name: 'apps-skudhs-view', params: { id: data.item.id } }"
              :to="{ name: 'apps-skudhs-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.serial }}</small>
          </b-media>
        </template>

        <template #cell(description)="data">

          <div class="text-nowrap">
            <span
              v-if="data.item.popup && data.item.popup.length"
              class="align-text-top text-primary"
            ><a
              @click="customPopup(data.item.popup)"
            >{{ data.item.description }}</a>
            </span>
            <span
              v-else-if="data.item.popup"
              class="align-text-top text-danger"
            >{{ data.item.description }}</span>
            <span
              v-else
              class="align-text-top"
            >{{ data.item.description && data.item.description.desc && data.item.description.desc[$i18n.locale] ? data.item.description.desc[$i18n.locale] : "" }}<br><small> {{ data.item.description && data.item.description.details && data.item.description.details[$i18n.locale]? data.item.description.details[$i18n.locale] : "" }} </small></span>
          </div>

        </template>

        <!-- Column: Object  -->
        <template #cell(machineId)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveSkudhMachineText(data.item.machineId) }}</span>
          </div>
        </template>

        <!-- Column: Company -->
        <template #cell(company_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.company_name }}</span><br>
            <small><span class="align-text-top text-capitalize">{{ data.item.object_name }}</span></small>
          </div>
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              <feather-icon
                :icon="rowClass(data.item, 'icon')"
                :class="rowClass(data.item, 'class')"
                size="18"
              />
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveSkudhStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!--b-dropdown-item :to="{ name: 'apps-skudhs-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item-->

            <b-dropdown-item :to="{ name: 'apps-skudhs-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteSkudh(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalSkudhs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  // BCard, BRow, BCol, BFormInput,
  BButton,
  // BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol,
  // BFormInput,
  BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'

import Vue from 'vue'
import store from '@/store'
import SkudhsListFilters from './SkudhsListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useSkudhsList from './useSkudhsList'
import skudhStoreModule from '../skudhStoreModule'

export default {
  components: {
    SkudhsListFilters,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    rowClass(item, type) {
      let color = ''
      if (item.description && item.description.color) color = item.description.color
      // console.log(item, color)
      let icon = ''
      let cls = ''
      if (color /* || true */) {
        if (color === 'success') {
          icon = 'CheckSquareIcon'
          cls = 'text-success'
        } else if (color === 'warning') {
          icon = 'AlertTriangleIcon'
          cls = 'text-warning'
        } else if (color === 'danger') {
          icon = 'XSquareIcon'
          cls = 'text-danger'
        } else /* if (color === 'light') */ {
          icon = 'InfoIcon'
          cls = 'text-dark'
        }
      }
      // console.log(icon, cls)
      return type === 'icon' ? icon : cls
    },
  },
  // setup(props) {
  setup() {
    // console.log(props)

    const USER_APP_STORE_MODULE_NAME = 'app-skudh'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, skudhStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewSkudhSidebarActive = ref(false)

    /*

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchSkudhs,
      fetchSkudhsFile,
      tableColumns,
      perPage,
      currentPage,
      totalSkudhs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSkudhListTable,
      refetchData,

      // UI
      resolveSkudhRoleVariant,
      resolveSkudhRoleIcon,
      resolveSkudhStatusVariant,
      resolveSkudhCompanyText,
      resolveSkudhMachineText,

      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,

      machineOptions,
      objectOptions,
      companyOptions,

      deleteSkudh,

      rangePicker,

    } = useSkudhsList()

    const customPopup = item => {
      // console.log(item)

      Vue.swal({
        title: 'Z-REPORT',
        // eslint-disable-next-line global-require
        html: item,
        icon: 'none',
        // imageWidth: 350,
        // imageHeight: 350,
        // imageAlt: 'Custom image',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    return {

      // Sidebar
      isAddNewSkudhSidebarActive,

      fetchSkudhs,
      fetchSkudhsFile,
      tableColumns,
      perPage,
      currentPage,
      totalSkudhs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSkudhListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveSkudhRoleVariant,
      resolveSkudhRoleIcon,
      resolveSkudhStatusVariant,
      resolveSkudhCompanyText,
      resolveSkudhMachineText,

      machineOptions,
      companyOptions,
      objectOptions,
      statusOptions,

      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,

      deleteSkudh,
      customPopup,

      rangePicker,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
    table .npd_15_0 {
        padding-left: 15px;
        padding-right: 5px;
    }
    table .npd_0_15 {
        padding-left: 5px;
        padding-right: 15px;
    }
    table .npd_0_0 {
        padding-left: 5px;
        padding-right: 5px;
    }
    table .npd_0_ {
        padding-left: 5px;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
